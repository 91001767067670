/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, Grid, IconButton, Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: '0px 0px 30px 0px rgba(50, 50, 50, 0.2)',
    borderRadius: 10,
    padding: 40
  }
}));

const Results = (props) => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(0);

  const sortByDay = (v1, v2) => {
    const dayMap = { U: 0, WU: 1, M: 2, MR: 3, T: 4, TS: 5, W: 6, R: 7, S: 8 };
    return dayMap[v1] - dayMap[v2];
  };

  const columns = [
    { field: 'crn', headerName: 'CRN', width: 75, sortable: false },
    {
      field: 'course',
      headerName: 'Course #',
      sortable: false,
      valueGetter: (params) =>
                `${params.row.dept || ''} ${params.row.num || ''}`,
      width: 120
    },
    { field: 'section', headerName: 'Section', width: 90, sortable: false },
    { field: 'day', headerName: 'Day', width: 80, sortable: false },
    {
      field: 'time',
      headerName: 'Time',
      width: 100,
      sortable: false,
      renderCell: (params) => (
                <div style={{ whiteSpace: 'pre-wrap' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </div>
      )
    },
    {
      field: 'name',
      headerName: 'Course Name',
      width: 175,
      sortable: false,
      renderCell: (params) => (
                <div style={{ whiteSpace: 'pre-wrap' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </div>
      )
    },
    {
      field: 'instructor',
      headerName: 'Instructor',
      width: 150,
      sortable: false,
      renderCell: (params) => (
                <div style={{ whiteSpace: 'pre-wrap' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </div>
      )
    },
    { field: 'remaining', headerName: 'Remaining', width: 125, sortable: false }
  ];

  useEffect(() => {
    setCurrentPage(0);
  }, [props.schedules]);

  const download = () => {
    const doc = new jsPDF();
    const col = ['CRN', 'Course #', 'Section', 'Day', 'Time', 'Course Name', 'Instructor', 'Remaining'];
    const rows = [];
    props.schedules[currentPage].forEach((el) => {
      rows.push([el.crn, el.dept + '' + el.num, el.section, el.day.toString(), el.time.toString(), el.name, el.instructor, el.remaining]);
    });
    doc.text('gadwal.co', 10, 10);
    doc.autoTable(col, rows);
    doc.save('Schedule - gadwal.co.pdf');
  };

  return (
        <Card className={classes.card}>
            <Grid container direction="row" alignItems="center" justify="space-between" style={{ marginBottom: 15 }}>
                <Button style={{ background: '#e2eeff' }} onClick={download}>Download Schedule</Button>
                <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
                    <IconButton size="small" disabled={currentPage === (props.schedules.length - 1)} onClick={() => {
                      setCurrentPage((prevpage) => prevpage + 1);
                    }}>
                        <ChevronRight/>
                    </IconButton>
                    <Typography
                        variant="body2"><i>Schedule {currentPage + 1} of {props.schedules.length}</i></Typography>
                    <IconButton size="small" disabled={currentPage === 0} onClick={() => {
                      setCurrentPage((prevpage) => prevpage - 1);
                    }}>
                        <ChevronLeft/>
                    </IconButton>
                </div>
            </Grid>
            <div style={{ height: 500 }}>
                <DataGrid rows={props.schedules[currentPage].sort((a, b) => sortByDay(a.day[0], b.day[0]))} columns={columns} pageSize={10} disableColumnMenu
                          rowHeight={60} disableSelectionOnClick hideFooterPagination/>
            </div>
        </Card>
  );
};

export default Results;
