/* eslint-disable react/react-in-jsx-scope */
import Home from './components/Home';

const App = () => {
  return (
        <div style={{ background: 'rgb(245,245,245)' }}>
            <Home/>
        </div>
  );
};

export default App;
