import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button, Checkbox,
  Divider, FormControlLabel, Hidden,
  IconButton, InputAdornment,
  List,
  ListItem,
  ListItemText,
  Paper, Step, StepLabel, Stepper, TextField,
  Typography
} from '@material-ui/core';
import { ChevronRight, Close, Search } from '@material-ui/icons';
import Fuse from 'fuse.js';

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: '0px 0px 30px 0px rgba(50, 50, 50, 0.2)',
    borderRadius: 10,
    width: '50%',
    height: '65%',
    position: 'absolute',
    padding: 40,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      minWidth: '70vw',
      minHeight: '70vh'
    }
  },
  header: {
    fontWeight: 500,
    color: '#0f385a',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20
    }
  },
  container: {
    overflowY: 'scroll',
    flexGrow: 1,
    marginBottom: 20
  },
  list1: {},
  list1sel: {
    background: '#e7e7e7'
  },
  list2: {
    paddingLeft: 40
  },
  list3: {
    paddingLeft: 80
  },
  input: {
    marginBottom: 20
  }
}));

const Courses = (props) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [search2, setSearch2] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Select Department', 'Select Course', 'Select Section(s)'];
  const [courseList, setCourseList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [filtered, setFiltered] = useState(props.courses);
  const [checked, setChecked] = useState([]);

  const scrollRef = useRef();

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const options = {
    keys: ['deptName', 'courses.courseName'],
    threshold: 0
  };

  const options2 = {
    keys: ['courseName', 'sections.num'],
    threshold: 0
  };

  const handleClick = (step, data) => {
    scrollRef.current.scrollTo(0, 0);
    if (step === 1) {
      setCourseList(data);
      setFiltered(data);
    }
    if (step === 2) {
      setSectionList(data);
      setChecked([]);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const fuseSearch = (step) => {
    if (step === 1) {
      if (search.length > 0) {
        const fuse = new Fuse(props.courses, options);
        const res = fuse.search(search);
        const result = res.map((el) => el.item);
        setFiltered(result);
      } else {
        setFiltered(props.courses);
      }
    } else if (step === 2) {
      if (search2.length > 0) {
        const fuse = new Fuse(courseList, options2);
        const res = fuse.search(search2);
        const result = res.map((el) => el.item);
        setFiltered(result);
      } else {
        setFiltered(courseList);
      }
    }
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleSearch2Change = (event) => {
    setSearch2(event.target.value);
  };

  const submitCourse = () => {
    const currentIndex = checked.indexOf('99');
    const newChecked = [...checked];

    if (currentIndex !== -1) {
      newChecked.splice(currentIndex, 1);
    }

    props.appendCourse(props.id, newChecked);
    props.handleClose();
  };

  const handleAll = () => {
    const currentIndex = checked.indexOf('99');
    if (currentIndex === -1) {
      const newChecked = [];
      newChecked.push('99');
      sectionList.forEach((el) => {
        el.time.indexOf('TBA') === -1 && newChecked.push(el.crn);
      });
      setChecked(newChecked);
    } else { setChecked([]); }
  };

  useEffect(() => {
    if (activeStep === 0) { fuseSearch(1); } else if (activeStep === 1) { fuseSearch(2); }
  }, [search, search2]);

  const deptStep = () => {
    return (
            <>
                <TextField
                    id="deptsearch"
                    className={classes.input}
                    value={search}
                    size="small"
                    onChange={handleSearchChange}
                    InputLabelProps={{ shrink: false }}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                            <InputAdornment position="start">
                                <Search/>
                            </InputAdornment>
                      )
                    }}
                />
                <div className={classes.container} ref={scrollRef}>
                    <List style={{ padding: 0 }}>
                        {filtered.map((el) => (<>
                                <ListItem button onClick={() => handleClick(1, el.courses)} id={el.deptName} key={el.deptName}
                                          className={classes.list1}>
                                    <ListItemText primary={el.deptName}/>
                                    <ChevronRight/>
                                </ListItem>
                                <Divider/>
                            </>
                        ))}
                    </List>
                </div>
            </>
    );
  };

  const courseStep = () => {
    return (
            <>
                <TextField
                    id="coursesearch"
                    className={classes.input}
                    value={search2}
                    fullWidth
                    size="small"
                    onChange={handleSearch2Change}
                    InputLabelProps={{ shrink: false }}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                            <InputAdornment position="start">
                                <Search/>
                            </InputAdornment>
                      )
                    }}
                />
                <div className={classes.container} ref={scrollRef}>
                    <List style={{ padding: 0 }}>
                        {filtered.map((el) => (<>
                                <ListItem button onClick={() => handleClick(2, el.sections)}
                                          id={el.courseName}
                                          key={el.courseName}
                                          className={classes.list1}>
                                    <ListItemText
                                        primary={el.sections[0].dept + ' ' + el.sections[0].num + ': ' + el.courseName}/>
                                    <ChevronRight/>
                                </ListItem>
                                <Divider/>
                            </>
                        ))}
                    </List>
                </div>

                <Button onClick={() => {
                  setActiveStep((prevActiveStep) => prevActiveStep - 1);
                  setFiltered(props.courses);
                  scrollRef.current.scrollTo(0, 0);
                }} style={{}}>Back</Button>
            </>
    );
  };

  const sectionStep = () => {
    return (
            <>
                <div style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%' }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={checked.indexOf('99') !== -1}
                                onChange={handleAll}
                            />
                        }
                        label="Select All"
                    />
                </div>
                <div className={classes.container} ref={scrollRef}>
                    <List style={{ padding: 0 }}>
                        {sectionList.map((el) => (<>
                                <ListItem id={el.crn} key={el.crn}>
                                    <div style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                      width: '100%'
                                    }}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Typography
                                                variant="subtitle1"><i>Section {el.section} ({el.crn})</i></Typography>
                                            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 20 }}>
                                                <Typography variant="subtitle1">{el.instructor}</Typography>
                                                <Typography variant="subtitle1">{el.day} {el.time}</Typography>
                                                <Typography variant="subtitle1">Remaining: {el.remaining}</Typography>
                                            </div>
                                        </div>
                                        <Checkbox
                                            edge="end"
                                            color="primary"
                                            disabled={el.time.indexOf('TBA') !== -1}
                                            onChange={handleToggle(el.crn)}
                                            checked={checked.indexOf(el.crn) !== -1}
                                        />
                                    </div>
                                </ListItem>
                                <Divider/>
                            </>
                        ))}
                    </List>
                </div>

                <Button onClick={submitCourse} style={{}}>Submit</Button>

                <Button onClick={() => {
                  setActiveStep((prevActiveStep) => prevActiveStep - 1);
                  setFiltered(courseList);
                  scrollRef.current.scrollTo(0, 0);
                }} style={{}}>Back</Button>

            </>
    );
  };

  return (
        <Paper className={classes.paper}>
            <IconButton size="small" style={{ position: 'absolute', top: 20, right: 20 }}
                        onClick={props.handleClose}>
                <Close fontSize="small"/>
            </IconButton>
            <Typography variant="h5" className={classes.header}>Add Courses</Typography>
            <Hidden smDown>
                <Stepper activeStep={activeStep} style={{ padding: 0, marginTop: 40, marginBottom: 40 }}>
                    {steps.map((label, index) => {
                      const stepProps = {};
                      const labelProps = {};
                      return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                      );
                    })}
                </Stepper>
            </Hidden>
            {(() => {
              if (activeStep === 0) {
                return deptStep();
              } else if (activeStep === 1) {
                return courseStep();
              } else if (activeStep === 2) {
                return sectionStep();
              }
            })()}
        </Paper>
  );
};

export default Courses;
