import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Card,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Popover,
  Snackbar,
  Typography,
  FormControl,
  InputLabel,
  Select
} from '@material-ui/core';
import axios from 'axios';
import Courses from './Courses';
import MuiAlert from '@material-ui/lab/Alert';
import Results from './Results';
import { HelpOutline, MoreVert } from '@material-ui/icons';

function Alert (props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    width: 'inherit',
    zoom: '90%',
    padding: 80,
    [theme.breakpoints.down('xs')]: {
      padding: 20
    }
  },
  card: {
    boxShadow: '0px 0px 30px 0px rgba(50, 50, 50, 0.2)',
    borderRadius: 10,
    padding: 40
  },
  button: {
    background: '#e2eeff',
    color: 'black'
  },
  formControl: {
    minWidth: 120
  }
}));

const ip = 'https://gadwal.co/api';
// const ip = 'http://localhost:3001/api';

const Home = () => {
  const classes = useStyles();
  const myRef = useRef(null);

  const [courses, setCourses] = useState([]);
  const [sections, setSections] = useState([]);
  const [courseList] = useState([[], [], [], [], [], [], [], []]);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [, setError] = useState(false);
  const [, setNone] = useState(false);
  const [noneError, setNoneError] = useState(false);
  const [open, setOpen] = useState(false);
  const [addId, setAddId] = useState(0);
  const [schedules, setSchedules] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuId, setMenuId] = useState(null);
  const [errorMsg, setMessage] = useState('');
  const [info, setInfo] = useState(false);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(2400);

  const handleClick = () => {
    setAnchorEl(null);
    setMenuId(null);
  };

  const handleMenuOpen = (event, id) => {
    setAnchorEl(event.currentTarget);
    setMenuId(id);
  };

  const handleOpen = (id) => {
    if (courses.length > 0) { setAddId(id); }
    setOpen(true);
  };

  const appendCourse = (id, crn) => {
    courseList[id] = crn;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNoneClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNoneError(false);
  };

  const handleInfo = (event) => {
    setInfo(true);
    setAnchorEl2(event.currentTarget);
  };

  const handleInfoClose = () => {
    setAnchorEl2(false);
    setInfo(false);
  };

  const generate = () => {
    const result = courseList.map(it => it.filter(_ => true)).filter(sub => sub.length);
    if (result.length > 0) {
      axios.post(ip + '/generateSchedule', { courses: courseList, start: start, end: end }).then((res) => {
        if (res.data.length === 0) {
          setNone(true);
          setNoneError(true);
          setMessage('Uh oh! No available schedules due to clashing courses or unsuitable start/end times.');
        } else {
          myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        setSchedules(res.data);
      }).catch((error) => {
        console.log(error);
        setError(true);
      });
    } else {
      setNoneError(true);
      setMessage('Please add one or more courses before trying to generate a schedule.');
    }
  };

  useEffect(() => {
    axios.get(ip + '/getCourses').then((res) => {
      setCourses(res.data);
    }).catch((error) => {
      console.log(error);
      setError(true);
    });

    axios.get(ip + '/getSections').then((res) => {
      setSections(res.data);
    }).catch((error) => {
      console.log(error);
      setError(true);
    });

    axios.get(ip + '/getLastUpdate').then((res) => {
      setLastUpdate(res.data);
    }).catch((error) => {
      console.log(error);
      setError(true);
    });
  }, []);

  const options = { dateStyle: 'full', timeStyle: 'medium' };

  return (
        <div className={classes.root}>
            <Snackbar open={noneError} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} onClose={handleNoneClose}>
                <Alert onClose={handleNoneClose} severity="error">
                    {errorMsg}
                </Alert>
            </Snackbar>
            {/* <Typography style={{position: 'absolute', top: 10, right: 5, writingMode: 'vertical-rl', transform: 'rotate(180deg)'}}>Made by Seif Shalan</Typography> */}
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Courses id={addId} courses={courses} sections={sections} handleClose={handleClose}
                         appendCourse={appendCourse}/>
            </Modal>
            <Popover open={info} onClose={handleInfoClose} anchorEl={anchorEl2} id="hi"
                     anchorOrigin={{
                       vertical: 'bottom',
                       horizontal: 'center'
                     }}
                     transformOrigin={{
                       vertical: 'top',
                       horizontal: 'center'
                     }}
            >
                <Typography style={{ padding: 10 }}>Created by Seif Shalan. For any suggestions or bug reports, please message me on <a href="https://www.facebook.com/seifshalan/">Facebook</a></Typography>
            </Popover>
            <img src="logo.svg" alt="logo" width="200px"/>
            <Typography variant="subtitle1" style={{ fontWeight: 500, color: '#0f385a', marginBottom: 20 }}>Fall 2024 - Last
                Updated: <i>{new Date(lastUpdate).toLocaleString('en-GB', options)}</i><IconButton size="small" onClick={handleInfo}><HelpOutline/></IconButton></Typography>
            {/* {Object.keys(sections).length} */}
            {/* {JSON.stringify(courseList)} */}
            <Grid container direction="row" spacing={3}>
                <Grid item xs={12} lg={4}>
                    <Grid container direction="column" alignItems='stretch' spacing={3}>
                        <Grid item style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Typography variant="h5" style={{ color: '#0f385a', flexGrow: 1 }}>Course List</Typography>
                            <Button onClick={generate} className={classes.button}>Generate Schedules</Button>
                        </Grid>
                        <Grid item style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel>Start Time</InputLabel>
                                <Select
                                labelId="Start time"
                                value={start}
                                onChange={(ev) => setStart(ev.target.value)}
                                label="Start time"
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                  }
                                }}
                                >
                                    <MenuItem value={0}>Any</MenuItem>
                                    <MenuItem value={830}>08:30 AM</MenuItem>
                                    <MenuItem value={1000}>10:00 AM</MenuItem>
                                    <MenuItem value={1130}>11:30 AM</MenuItem>
                                    <MenuItem value={1300}>01:00 PM</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel>End Time</InputLabel>
                                <Select
                                labelId="End time"
                                value={end}
                                onChange={(ev) => setEnd(ev.target.value)}
                                label="End time"
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                  }
                                }}
                                >
                                    <MenuItem value={2400}>Any</MenuItem>
                                    <MenuItem value={1300}>01:00 PM</MenuItem>
                                    <MenuItem value={1530}>03:30 PM</MenuItem>
                                    <MenuItem value={1700}>05:00 PM</MenuItem>
                                    <MenuItem value={1830}>06:30 PM</MenuItem>
                                    <MenuItem value={2000}>08:00 PM</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {(() => {
                          const html = [];
                          for (let i = 0; i < courseList.length; i++) {
                            html.push(
                                    <Grid item>
                                        <Card className={classes.card}>
                                            <Menu
                                                open={menuId === i}
                                                anchorEl={anchorEl}
                                                keepMounted
                                                onClose={handleClick}
                                                style={{ marginLeft: -40, marginTop: -40 }}
                                            >
                                                <MenuItem onClick={() => { handleClick(); handleOpen(i); }}>Edit Course</MenuItem>
                                                <MenuItem onClick={() => { handleClick(); courseList[i] = []; }}>Remove Course</MenuItem>
                                            </Menu>
                                            <Grid container direction="column">
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <Typography variant="h6" style={{
                                                      color: '#0f385a',
                                                      flexGrow: 1
                                                    }}>Course {i + 1}</Typography>
                                                    {courseList[i].length > 0
                                                      ? <IconButton size="small" onClick={(e) => handleMenuOpen(e, i)}><MoreVert/>
                                                        </IconButton>
                                                      : <Button className={classes.button}
                                                            onClick={() => handleOpen(i)}>Add Course</Button>}
                                                </div>
                                                {(() => {
                                                  if (courseList[i].length > 0) {
                                                    return (
                                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                <Typography variant="h6" style={{
                                                                  color: '#0f385a',
                                                                  fontSize: '1.1rem'
                                                                }}>{sections[courseList[i][0]].dept + ' ' + sections[courseList[i][0]].num + ': ' + sections[courseList[i][0]].name}</Typography>
                                                                <Typography><i>Selected
                                                                    CRNs: </i> {(() => {
                                                                  const html = [];
                                                                  courseList[i].forEach((el) => {
                                                                    html.push(el + ' (' + sections[el].day + ' ' + sections[el].time + ')');
                                                                  });
                                                                  return html.join(', ');
                                                                })()}</Typography>
                                                            </div>
                                                    );
                                                  }
                                                })()}
                                            </Grid>
                                        </Card>
                                    </Grid>
                            );
                          }
                          return html;
                        })()}
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={8} ref={myRef}>{
                    schedules.length > 0
                      ? <Grid container direction="column" spacing={3}>
                        <Grid item style={{ marginBottom: 4 }}>
                            <Typography variant="h5" style={{ color: '#0f385a', flexGrow: 1 }}>Available
                                Schedules</Typography>
                        </Grid>
                        <Grid item>
                            <Results schedules={schedules}/>
                        </Grid>
                    </Grid>
                      : <>
                    </>
                }
                </Grid>
            </Grid>
        </div>
  );
};

export default Home;
